import { Box, useTheme } from "@mui/material";
import Chip from "../../../Components/Chip";
import { Form } from "../../../system/useForm";
import TextBlock from "../../../Components/Layout/TextBlock";
import { useRecoilState } from "recoil";
import { customerChoice } from "../../../system/atoms/customerChoice";
import choice from "../../../Dialogs/choice";

interface Props {
	form: Form
}

const StepCollectionInstructions = (props: Props) => {
	const theme = useTheme();
	const [, setCustomerChoice] = useRecoilState(customerChoice);

	const displayCustomerReturnsMessage = async () => {
		const textMessage = <Box><Box>vielen Dank für Ihre Anmeldung zur Fahrzeugrückführung.</Box>
			<br /><Box>
				Aufgrund der anhaltend hohen Nachfrage nach Logistikkapazitäten für die Überführung von Fahrzeugen kann es vereinzelt zu Engpässen kommen. Dies führt leider dazu, dass sich die
				Abholung Ihres Fahrzeuges verzögern kann. In diesen Fällen bitten wir Sie um etwas Geduld.
				<br />
				Wir werden uns rechtzeitig vor Abholung des Leasingfahrzeuges bei Ihnen melden und eine Lösung finden.
			</Box>
			<br />
			<Box>
				Gleichzeitig möchten wir Ihnen die Option anbieten, das Fahrzeug selbst zu überführen.
			</Box>
			<br /><Box sx={{ fontWeight: "bold" }}>Leasingnehmer:</Box>
			<br />
				<Box>Als Leasingnehmer profitieren Sie dabei von einem Entfall der Wartezeit und einer Reduzierung der Rückholpauschale bei Eigenanlieferung auf 290,00 Euro.
				</Box>
				<Box>
					Wenn Sie diesen Service nutzen möchten, melden Sie sich bitte mindestens 2 Tage vor Anlieferung unter&nbsp;
					<a href="mailto:auftrag@aldautomotive.com" target="_top" style={{ color:theme.palette.secondary.main }}>auftrag@aldautomotive.com</a>.
					Für eine reibungslose Abwicklung benötigen wir das Kennzeichen und das Anlieferdatum.
					<br />
					Bitte denken Sie unbedingt daran, den Fahrzeugschein mitzubringen – eine Rückgabe ohne Fahrzeugschein ist leider nicht möglich.
					<br />
					Sehr gerne nehmen wir auch alle Zubehörteile entgegen, die Sie bei Auslieferung erhalten haben.
					Das Fahrzeug können Sie von Montag bis Freitag 9 – 17 Uhr zu unserem Sammelplatz Justus-von-Liebig-Strasse, 29683 Dorfmark verbringen.
				</Box>
			<br /><Box sx={{ fontWeight: "bold" }}>Handelspartner:</Box>
			<br />
			<Box>Wir bieten Ihnen einen Pauschalbetrag zur Überführung des Fahrzeugs. Bitte kontaktieren Sie uns dazu unter&nbsp;
				<a href="mailto:auftrag@aldautomotive.com" target="_top" style={{ color: theme.palette.secondary.main }}>auftrag@aldautomotive.com</a>.
			</Box>
			<br />
			<Box>Nach Ihrer Anfrage erhalten Sie schnellstmöglich eine Rückmeldung.</Box>
		<br />
		<Box>Wir danken Ihnen für Ihr Verständnis und Kooperation.</Box>
			<br /><Box>Sollten Sie in der Zwischenzeit noch Fragen haben, wenden Sie sich gerne an Ihre zuständige Kundenbetreuung.</Box>
			<br /><Box>Mit freundlichen Grüßen</Box>
			<Box>Ihr Team der ALD Automotive</Box>
		</Box>;

		const response = await choice(
			{ message: textMessage, title: "Sehr geehrte Damen und Herren,", maxWidth: "md", secondary: "Jetzt selbst anliefern" }
		);

		if (response === "secondary") {
			setCustomerChoice("CustomerDelivery");
		}
	};

	return (
		<Box>
			Sehr geehrter Kunde, bitte füllen Sie das nachfolgende Formular komplett aus.
			<br />
			<br />
			Bitte achten Sie vor allem auf korrekt ausgefüllte Kontaktdaten, da sonst der Auftrag nicht ordnungsgemäß ausgeführt werden kann.
			<br />
			<br />
			Für Rückfragen kontaktieren Sie bitte Ihren Kundenbetreuer.
			<br />
			<br />
			<br />
			<TextBlock
				primary="Aufgrund von Kapazitätsengpässen kann es aktuell zu Verzögerungen bei den Abholungen kommen."
			/>
			<Box mt={1}>
				<Chip
					color="secondary"
					label="Jetzt selbst anliefern und sparen"
					onClick={displayCustomerReturnsMessage}
				/>
			</Box>
		</Box>
	)
};

export default StepCollectionInstructions;
