import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import Layout from "../../Components/Layout/Layout";
import { Box, CircularProgress, Grid, MenuItem } from "@mui/material";
import ActionButton from "../../Components/ActionButton";
import { ImageReferences, CompoundPlace, RecursivePartial, UserRole, VehicleService } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import axios from "axios";
import TextBlock from "../../Components/Layout/TextBlock";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import moment from "moment";
import TileActions from "../../Components/Tiles/TileActions";
import changeCompany from "./changeCompany";
import changePrice from "./changePrice";
import LotScanContextMenu from "../Vehicles/LotScanContextMenu";
import useVehicle from "../../system/useVehicle";
import VehicleHeaderMenu from "../Vehicles/VehicleHeaderMenu";
import VehicleSubMenu from "../Vehicles/VehicleSubMenu";
import VehicleHeader from "../Vehicles/VehicleHeader";
import dateFormats from "../../system/dateFormats";
import Actions from "../../Components/Actions";
import changeAdditionalInformation from "./changeAdditionalInformation";
import changeText from "./changeText";
import uploadPictureProof from "./uploadPictureProof";
import Image from "../Vehicles/PictureSets/Image";
import HeaderMenu from "../../Components/Layout/HeaderMenu";
import useVehicleServicePermissions from "./useVehicleServicePermissions";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import fileDownload from "js-file-download";
import useCompoundPlaces from "../../system/useCompoundPlaces";
import VehicleServiceTypeIcon from "./VehicleServiceTypeIcon";
import Thumbnail from "../../Components/Thumbnail";
import useUser from "../../system/useUser";
import formatCurrency from "../../system/formatCurrency";
import VehicleNavigation from "Navigation/VehicleNavigation";
import LayoutSubTitle from "../../Components/Layout/LayoutSubTitle";
import VehicleTiresInfo from "./VehicleTiresInfo";
import { vehicleServiceStatusInformation } from "./VehicleServiceStatusInformation";

const VehicleServiceDetails = ({ history, match }: RouteComponentProps<any>) => {
	const id = match.params.id;
	const serviceId = match.params.serviceId;

	const [saving, setSaving] = useState(false);
	const [vehicleService, setVehicleService] = useState<VehicleService>();
	const [vehicle] = useVehicle(id);
	const [places] = useCompoundPlaces();
	const [currentPlace, setCurrentPlace] = useState<CompoundPlace>();

	const vehicleServicePermissions = useVehicleServicePermissions();

	useAsyncEffect(async () => {
		if (!serviceId) {
			setVehicleService(undefined);
			return;
		}

		const { data: vehicleService } = await axios.get<VehicleService>(`/api/vehicles/services/${serviceId}`);
		setVehicleService(vehicleService);
	}, [serviceId]);

	useEffect(() => {
		if (!vehicle?.placeId || !places) {
			setCurrentPlace(undefined);
			return;
		}

		const place = places.find((p) => p.id === vehicle.placeId);
		setCurrentPlace(place);
	}, [vehicle?.placeId, places]);

	const handleDeleteVehicleService = async () => {
		await axios.delete(`/api/vehicles/services/${serviceId}`);

		history.push(`/vehicles/${vehicle?.id}/services`);
	};

	const handleCancellation = async () => {
		const reason = await changeText({
			text: "",
			label: "Begründung",
			headline: "Auftrag abbrechen",
			required: true,
		});

		if (reason) {
			const { data: newVehicleService } = await axios.post(`/api/vehicles/services/${serviceId}/cancel`, {
				reason: reason,
			});

			setVehicleService(newVehicleService);
		}
	};

	const handleCompanyChange = async () => {
		const result = await changeCompany({ company: vehicleService?.assignedTo });

		await updateVehicleService({
			assignedTo: {
				name: result,
			},
		});
	};

	const handleConfirmation = async () => {
		const { data: vehicleService } = await axios.post<VehicleService>(
			`/api/vehicles/services/${serviceId}/confirm`,
		);

		setVehicleService(vehicleService);
	};

	let statusTile: StatusTileProps | null = null;
	if (vehicleService) {
		statusTile = vehicleServiceStatusInformation(vehicleService) as StatusTileProps;

		switch (vehicleService.status) {
			case "Created":
				statusTile.next = "Dienstleister zuweisen";
				statusTile.action = vehicleServicePermissions.canChangeCompany(vehicleService)
					? () => handleCompanyChange()
					: undefined;

				break;
			case "Requested":
				statusTile.next = "Bestätigen";
				statusTile.action = vehicleServicePermissions.canConfirmRequests
					? () => handleConfirmation()
					: undefined;
				statusTile.altNext = "Ablehnen";
				statusTile.altAction = vehicleServicePermissions.canRejectRequests
					? () => handleRejection()
					: undefined;

				break;
			case "Rejected":
				statusTile.next = "";
				statusTile.description = vehicleService.rejectionReason;

				break;
			case "Cancelled":
				statusTile.next = "";
				statusTile.description = vehicleService.cancellationReason;

				break;
			case "Assigned":
				statusTile.next = "Abschließen";
				statusTile.action = vehicleServicePermissions.canClose(vehicleService) ? () => close() : undefined;
				statusTile.altNext = vehicleService.serviceType.canBeStarted ? "Starten" : undefined;
				statusTile.altAction =
					vehicleService.serviceType.canBeStarted && vehicleServicePermissions.canStart(vehicleService)
						? () => handleStart()
						: undefined;

				break;
			case "Started":
				statusTile.next = "Abschließen";
				statusTile.action = vehicleServicePermissions.canClose(vehicleService) ? () => close() : undefined;
				statusTile.altNext = "Ausführung abbrechen";
				statusTile.altAction = vehicleServicePermissions.canStart(vehicleService)
					? () => handleUndoStart()
					: undefined;

				break;
			case "Completed":
				statusTile.next = "";
				statusTile.description = vehicleService.completionNote;

				break;
			case "Billed":
				statusTile.next = "";

				break;
		}
	}

	const handleRejection = async () => {
		const result = await changeText({
			text: vehicleService?.rejectionReason ?? "",
			label: "Begründung",
			headline: "Ablehnung",
		});

		const { data: newVehicleService } = await axios.post<VehicleService>(
			`/api/vehicles/services/${serviceId}/reject`,
			{
				text: result,
			},
		);

		setVehicleService(newVehicleService);
	};

	const handleStart = async () => {
		const { data: newVehicleService } = await axios.post<VehicleService>(
			`/api/vehicles/services/${serviceId}/start`,
		);

		setVehicleService(newVehicleService);
	};

	const handleUndoStart = async () => {
		const { data: newVehicleService } = await axios.post<VehicleService>(
			`/api/vehicles/services/${serviceId}/undo-start`,
		);

		setVehicleService(newVehicleService);
	};

	const close = async () => {
		if (!vehicleService || saving) {
			return;
		}

		if (vehicleService.serviceType.requiresPictureProof) {
			vehicleService.proofPicture = await uploadPictureProof({
				headline: "Fotonachweis",
				fileReference: vehicleService.proofPicture,
			});
		}

		if (vehicleService.serviceType.canHaveCompletionNote) {
			vehicleService.completionNote = await changeText({ headline: "Abschlussnotiz", label: "Notiz", text: "" });
		}

		setSaving(true);

		try {
			const { data: resultVehicleService } = await axios.post<VehicleService>(
				`/api/vehicles/services/${serviceId}/close`,
				{
					fileReference: vehicleService.proofPicture,
					completionNote: vehicleService.completionNote,
				},
			);
			setVehicleService(resultVehicleService);
		} finally {
			setSaving(false);
		}
	};

	const handlePriceChange = async () => {
		if (!vehicleServicePermissions.canSeePrices) return;

		const result = await changePrice({ price: vehicleService?.amount });

		if (result && result !== vehicleService?.amount) {
			await updateVehicleService({
				amount: result,
			});
		}
	};

	const handleAdditionalInformationChange = async () => {
		const result = await changeAdditionalInformation({ text: vehicleService?.additionalInformation ?? "" });

		if (result && result !== vehicleService?.additionalInformation) {
			await updateVehicleService({
				additionalInformation: result,
			});
		}
	};

	const updateVehicleService = async (update: RecursivePartial<VehicleService>) => {
		if (!vehicleService || saving) {
			return;
		}

		setSaving(true);

		try {
			const { data: updatedVehicleService } = await axios.put<VehicleService>(
				`/api/vehicles/services/${serviceId}`,
				update,
			);
			setVehicleService(updatedVehicleService);
		} finally {
			setSaving(false);
		}
	};

	const handleQrCodeDownload = async () => {
		const response = await axios.get(`/api/vehicles/services/${serviceId}/qr-code`, {
			responseType: "blob",
		});

		const fileName = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, fileName);
	};

	return (
		<Layout
			title="Tätigkeit"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
			contextMenu={[<LotScanContextMenu />, <VehicleHeaderMenu vehicle={vehicle} />]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
		>
			{(!vehicleService || !vehicle) && <CircularProgress size={48} />}
			{vehicleService && vehicle && (
				<>
					<Grid container justifyContent="space-between" alignItems="flex-end" spacing={2}>
						<Grid
							item
							sx={{
								cursor: "pointer",
							}}
							//className={clsx([classes.clickableCard], !!vehicleService.vehicleId)} //Marked to check mit Max
							onClick={
								vehicleService.vehicleId
									? () => history.push(`/vehicles/${vehicleService?.vehicleId}/services`)
									: undefined
							}
						>
							<VehicleHeader vehicle={vehicle} />
						</Grid>
						<Grid item>
							<Grid container spacing={2} alignItems="center" justifyContent="space-between">
								<Grid item>
									<Actions>
										{vehicleServicePermissions.canDelete(vehicleService) && (
											<ActionButton onClick={handleDeleteVehicleService}>
												Tätigkeit löschen
											</ActionButton>
										)}
										<ActionButton onClick={handleQrCodeDownload}>QR Code drucken</ActionButton>
									</Actions>
								</Grid>
								{vehicleServicePermissions.canCancel(vehicleService) && (
									<Grid item>
										<HeaderMenu>
											<MenuItem onClick={handleCancellation}>Auftrag abbrechen</MenuItem>
										</HeaderMenu>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Box mt={4}>
						<Grid container spacing={6}>
							<Grid item xs={12} md={6}>
								<Tile title="Dienstleister">
									{vehicleService.assignedTo && (
										<TileContent>
											<TextBlock
												primary={vehicleService.assignedTo?.name ?? ""}
												secondary={`Zugewiesen am ${vehicleService.status === "Requested" || vehicleService.status === "Rejected" ? moment(vehicleService.dateCreated).format(dateFormats.dateTime) : moment(vehicleService.dateAssigned).format(dateFormats.dateTime)}`}
											/>
										</TileContent>
									)}
									{!vehicleService.assignedTo && (
										<TileContent>
											<TextBlock
												primary="Noch nicht zugewiesen"
												secondary={
													vehicleServicePermissions.canChangeCompany(vehicleService)
														? "zum Zuweisen hier klicken"
														: ""
												}
											/>
										</TileContent>
									)}
									{vehicleServicePermissions.canChangeCompany(vehicleService) && (
										<TileActions>
											<ActionButton
												variant="text"
												hideOnDisabled
												title={
													vehicleService.assignedTo
														? "Zuweisung anpassen"
														: "Dienstleister zuweisen"
												}
												onClick={handleCompanyChange}
											>
												{vehicleService.assignedTo
													? "Zuweisung anpassen"
													: "Dienstleister zuweisen"}
											</ActionButton>
										</TileActions>
									)}
								</Tile>
							</Grid>
							<Grid item xs={12} md={6}>
								<Tile
									title={
										<Box display="flex" flexDirection="row" alignItems="center">
											<VehicleServiceTypeIcon vehicleServiceType={vehicleService.serviceType} />
											<Box ml={1}>
												<LayoutSubTitle label="Tätigkeit" />
											</Box>
										</Box>
									}
								>
									<TileContent>
										<TextBlock
											primary={vehicleService.text}
											secondary={`Erstellt am ${moment(vehicleService.dateCreated).format(dateFormats.dateTime)}`}
										/>
										<TextBlock
											primary={""}
											secondary={`Erstellt von ${vehicleService.createdBy.name}`}
										/>
									</TileContent>
								</Tile>
							</Grid>
							{statusTile && <StatusTile {...statusTile} />}
							{currentPlace && (
								<Grid item xs={12} md={6}>
									<Tile title="Fahrzeugstandort">
										<TileContent>{currentPlace.name}</TileContent>
									</Tile>
								</Grid>
							)}
							{(vehicleService.serviceType.canHaveRequestText || vehicleService.additionalInformation) && (
								<Grid item xs={12} md={6}>
									<Tile title="Zusatzinformationen">
										<TileContent>
											{vehicleService.additionalInformation &&
												vehicleService.additionalInformation}
											{!vehicleService.additionalInformation && <em>Keine</em>}
										</TileContent>
										{!vehicleService.dateCompleted && vehicleService.serviceType.canHaveRequestText && (
											<TileActions>
												<ActionButton
													variant="text"
													hideOnDisabled
													title="Zusatzinformationen anpassen"
													onClick={handleAdditionalInformationChange}
												>
													Zusatzinformationen anpassen
												</ActionButton>
											</TileActions>
										)}
									</Tile>
								</Grid>
							)}
							{vehicleServicePermissions.canSeePrices && (
								<Grid item xs={12} md={6}>
									<Tile title="Preis">
										<TileContent>
											<TextBlock
												primary={formatCurrency(vehicleService.amount)}
												secondary={
													vehicleService.dateBilled
														? `Abgerechnet am ${moment(vehicleService.dateBilled).format(dateFormats.dateTime)}`
														: "Noch nicht abgerechnet"
												}
											/>
										</TileContent>
										{vehicleServicePermissions.canChangePrice(vehicleService) && (
											<TileActions>
												<ActionButton
													variant="text"
													hideOnDisabled
													title="Preis anpassen"
													onClick={handlePriceChange}
												>
													Preis anpassen
												</ActionButton>
											</TileActions>
										)}
									</Tile>
								</Grid>
							)}
							<PictureTile vehicleService={vehicleService} />
							{vehicleService?.proofPicture && (
								<Grid item xs={12} md={6}>
									<Tile title="Fotonachweis">
										<TileContent>
											<Image
												hash={vehicleService.proofPicture.hash}
												filename={`${vehicleService.proofPicture.filename}.jpg`}
											/>
										</TileContent>
									</Tile>
								</Grid>
							)}
							{vehicleService?.serviceType.id === "ChangeTires" && (
								<Grid item xs={12} md={6}>
									<Tile title="Reifen und Felgen">
										<TileContent>
											<VehicleTiresInfo vehicle={vehicleService.vehicle} />
										</TileContent>
									</Tile>
								</Grid>
							)}
						</Grid>
					</Box>
				</>
			)}
		</Layout>
	);
};

interface PictureTileProps {
	vehicleService: VehicleService;
}

interface ImageReference {
	imageName: string;
	imageHash: string;
	origin: string;
}

const PictureTile = (props: PictureTileProps) => {
	const [imageReferences, setImageReferences] = useState<ImageReference[]>([]);
	const [, , , , hasAnyRole] = useUser();

	let imageRequest: ImageReferences[] = [];

	useAsyncEffect(async () => {
		if (imageRequest.length === 0 || imageReferences.length > 0) return;

		if (!hasAnyRole([UserRole.AldUser, UserRole.LotManager])) return;

		const response = await axios.post<ImageReference[]>(`/api/orders/transports/return-forms/images`, {
			vin: props.vehicleService.vin,
			images: imageRequest,
		});

		setImageReferences(response.data);
	}, [props.vehicleService]);

	const serviceTypeId = props.vehicleService.serviceType.id;

	if (serviceTypeId === "UnstickSmall" || serviceTypeId === "UnstickBig" || serviceTypeId === "UnstickFull") {
		imageRequest = ["frontLeft", "frontRight", "backLeft", "backRight"];
	} else if (serviceTypeId === "ChangeTires") {
		imageRequest = ["suppliedTiresAndRims", "tiresAndRims"];
	}

	return (
		<>
			{imageRequest.length > 0 && (
				<Grid item xs={12} md={6}>
					<Tile title="Bilder">
						<TileContent>
							<Grid container>
								{!imageReferences && <CircularProgress size={48} />}
								{imageReferences &&
									imageReferences
										.filter((f) => !!f.imageHash)
										.map((m, i) => (
											<Box my={1}>
												<Thumbnail
													width={300}
													height={200}
													asyncUrl={`/api/storage/${m.imageHash}/thumbnail`}
												/>
											</Box>
										))}
							</Grid>
						</TileContent>
					</Tile>
				</Grid>
			)}
		</>
	);
};

interface StatusTileProps {
	text: string;
	date: string;
	next: string;
	action?: (() => Promise<void>) | undefined;
	altNext?: string;
	altAction?: (() => Promise<void>) | undefined;
	description?: string;
}

const StatusTile = (props: StatusTileProps) => {
	if (!props.text) {
		return null;
	}

	return (
		<Grid item xs={12} md={6}>
			<Tile title="Status">
				<TileContent>
					<TextBlock primary={props.text} secondary={props.date} />
					{props.description && (
						<Box mt={2}>
							<em>{props.description}</em>
						</Box>
					)}
				</TileContent>
				<TileActions>
					<ActionButton variant="text" hideOnDisabled title={props.next} onClick={props.action}>
						{props.next}
					</ActionButton>
					<ActionButton
						variant="text"
						color="secondary"
						hideOnDisabled
						title={props.altNext}
						onClick={props.altAction}
					>
						{props.altNext}
					</ActionButton>
				</TileActions>
			</Tile>
		</Grid>
	);
};

export default VehicleServiceDetails;
