import Sidebar from "../../../Components/Sidebar/Sidebar";
import SidebarGroup from "../../../Components/Sidebar/SidebarGroup";
import SidebarButton from "../../../Components/Sidebar/SidebarButton";
import { Sale, UserRole } from "../../../system/Domain";
import { useHistory, useParams } from "react-router";
import { CurrentSalesOrder } from "./SalesOrderPage";
import axios from "axios";
import ask from "../../../Dialogs/ask";
import useUser from "../../../system/useUser";
import moment from "moment";

interface Props {
	currentSalesOrder: CurrentSalesOrder | null
	onChange: (sale: Sale) => void
}

const SalesOrderSidebar = ({ currentSalesOrder, onChange }: Props) => {
	const history = useHistory();
	const { id } = useParams<{ id: string }>();
	const [, , hasRole, , hasAnyRole] = useUser();

	const handleBack = () => {
		history.push(`/sales/${id}`);
	};

	const handleSendMail = () => {
		history.push(`/sales/${id}/orders/${currentSalesOrder?.order.id}/send-documents`);
	};

	const handleCancelSales = async () => {
		const answer = await ask("Soll der Verkauf wirklich storniert werden?");
		if (answer.yes) {
			const { data: newSales } = await axios.post<Sale>(`/api/sales/${id}/cancel-order`, {});
			onChange(newSales);
		}
	};

	const handleRevokeShipping = async () => {
		const answer = await ask("Soll die Auslieferung wirklich zurückgenommen werden?");
		if (answer.yes) {
			const { data: newSales } = await axios.post<Sale>(`/api/sales/${id}/revoke-shipping`, {
				revokingDate: moment()
			});
			onChange(newSales);
		}
	};

	const handleShipVehicle = () => {
		history.push(`/vehicles/ship?saleId=${currentSalesOrder?.sale.id}`);
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					onClick={handleBack}
				>
					Verkauf
				</SidebarButton>
				<SidebarButton onClick={handleSendMail}>
					Email
				</SidebarButton>
				{currentSalesOrder && currentSalesOrder.order.status !== "Cancelled" && (
					<SidebarButton
						onClick={handleCancelSales}
						disabled={!hasRole(UserRole.SalesAdmin)}
					>
						Verkauf stornieren
					</SidebarButton>
				)}
				{currentSalesOrder && currentSalesOrder.sale.status === "ReadyForShipping" && (
					<SidebarButton onClick={handleShipVehicle}>
						Fahrzeug ausliefern
					</SidebarButton>
				)}
				{hasAnyRole([UserRole.AldAdmin, UserRole.SalesAdmin]) && currentSalesOrder && currentSalesOrder.sale.status === "Shipped" && (
					<SidebarButton onClick={handleRevokeShipping}>
						Auslieferung zurücknehmen
					</SidebarButton>
				)}
			</SidebarGroup>
		</Sidebar>
	);
};

export default SalesOrderSidebar;
