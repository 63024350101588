import axios from 'axios';
import fileDownload from 'js-file-download';
import { useState } from 'react';
import { GetApp } from '@mui/icons-material';
import { Box, IconButton, SxProps } from '@mui/material';
import parseContentDispositionFilename from '../../../system/parseContentDispositionFilename';
import useAsyncEffect from '../../../system/useAsyncEffect';
import PictureUpload from "../../../Components/PictureUpload";
import { ImageUploadProps } from "./VehiclePictureSets";

export enum ImageViewMode {
	Default = "Default",
	Replace = "Replace"
}

interface Props {
	hash: string
	filename: string
	isInvalid?: boolean
	className?: string
	sxProps?: SxProps
	viewMode?: ImageViewMode
	setter?: (replaceImageProps: ImageUploadProps) => void
	pictureName?: string
}

export default ({ hash, filename, isInvalid, className, sxProps, viewMode, setter, pictureName }: Props) => {
	const [imageUrl, setImageUrl] = useState<string | null>(null);
	const [isHovered, setHover] = useState(true);
	viewMode = viewMode || ImageViewMode.Default;

	useAsyncEffect(async () => {
		await setThumbnail(hash);
	}, [hash]);

	const download = async () => {
		const response = await axios.get(`/api/storage/${hash}`, {
			responseType: "blob"
		});

		const contentDispositionFilename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename || contentDispositionFilename);
	};

	const setThumbnail = async (hash: string) => {
		if (!hash) {
			return;
		}

		const response = await axios.get(`/api/storage/${hash}/thumbnail`, {
			responseType: "blob"
		});

		const newImgUrl = URL.createObjectURL(response.data);
		setImageUrl(newImgUrl);
	};

	if (!imageUrl) return null;

	return (
		<>
			{viewMode === ImageViewMode.Default && (
				<div
					style={{
						position: "relative",
						lineHeight: "1em"
					}}
					onMouseOver={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
				>
					<Box
						component="img"
						sx={sxProps}
						alt="car_image"
						className={className}
						style={{
							opacity: isInvalid ? 0.25 : undefined
						}}
						src={imageUrl}
					/>

					{isHovered && viewMode === ImageViewMode.Default && (
						<div
							style={{
								position: "absolute",
								top: 5,
								right: 5
							}}
						>
							<IconButton
								color="primary"
								onClick={download}
							>
								<GetApp />
							</IconButton>
						</div>
					)}
				</div>
			)}

			{viewMode === ImageViewMode.Replace && (
				<div style={{
					position: "relative",
					lineHeight: "1em"
				}}>
					<PictureUpload
						disabled={false}
						fullWidth={false}
						readOnly={false}
						previewUrl={imageUrl}
						setter={setter}
						pictureName={pictureName}
					/>
				</div>
			)}
		</>
	);
}
