import { Box } from "@mui/material";
import axios from "axios";
import ActionButton from "Components/ActionButton";
import Loading from "Components/Loading";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import parseContentDispositionFilename from "system/parseContentDispositionFilename";
import useAxiosEffect from "system/useAxiosEffect";
import Layout from "../../Components/Layout/Layout";

interface DownloadedFile {
	name: string;
	data: Blob;
}

export default () => {
	const { hash } = useParams<{ hash: string }>();
	const [file, setFile] = useState<DownloadedFile>();

	useAxiosEffect(async (config) => {
		if (!hash) {
			return;
		}

		const response = await axios.get(`/api/storage/${hash}`, {
			...config,
			responseType: "blob"
		});

		const name = parseContentDispositionFilename(response.headers["content-disposition"]);

		setFile({
			name,
			data: response.data
		});
	}, [hash]);

	useEffect(() => {
		if (!file) {
			return;
		}

		fileDownload(file.data, file.name);
	}, [file]);

	return (
		<Layout title="Download">
			{!file && <Loading />}
			{file && (
				<Box textAlign="center">
					<Box mb={2}>{file.name}</Box>
					<ActionButton variant="text" onClick={() => fileDownload(file.data, file.name)}>
						Download
					</ActionButton>
				</Box>
			)}
		</Layout>
	);
};
