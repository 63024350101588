import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';

import { Block, Refresh } from '@mui/icons-material';
import {
    Box, Card, CardContent, Chip, IconButton, TableBody, TableCell, TableHead, TableRow, Tooltip,
    Typography
} from '@mui/material';

import Actions from '../../../Components/Actions';
import Table from '../../../Components/Table';
import ask from '../../../Dialogs/ask';
import { Sale, SaleOrder, UserRole } from '../../../system/Domain';
import formatCurrency from '../../../system/formatCurrency';
import useUser from '../../../system/useUser';

interface Props {
	sale : Sale
	order : SaleOrder
	onSaleChanged? : (sale : Sale) => void
}

const SalesOrderCard = ({ order, sale, onSaleChanged } : Props) => {
	const [busy, setBusy] = useState(false);
	const [, , hasRole, ,] = useUser();

	let label;
	switch (order.status) {
		case "Cancelled":
			label = "Abgebrochen";
			break;
		case "Invoiced":
			label = "Verkauft";
			break;
		default:
			label = "Bestellt";
			break;
	}

	const createHandleRefresh = () => {
		if (onSaleChanged && order.status === "Active") {
			return async () => {
				const { data: newSales } = await axios.post<Sale>(`/api/sales/${sale.id}/sold`);
				onSaleChanged(newSales);
			}
		}
		return undefined;
	};

	const createHandleCancelOrder = () => {
		const cancelableStates = ["Invoiced", "Active"];

		if (onSaleChanged && cancelableStates.includes(order.status)) {
			return async () => {
				const answer = await ask("Soll die Bestellung/der Verkauf wirklich storniert werden?");
				if (answer.yes) {
					const { data: newSales } = await axios.post<Sale>(`/api/sales/${sale.id}/cancel-order`, {});
					onSaleChanged(newSales);
				}
			}
		}
	};

	const handleWithBusyIndicator = (func? : () => void) => {
		if (!func) {
			return undefined;
		}

		return async () => {
			setBusy(true);
			try {
				await func();
			} finally {
				setBusy(false);
			}
		};
	};

	let buyerName = order.buyer.name;
	if (order.buyer.name2) {
		buyerName = `${buyerName} ${order.buyer.name2}`;
	}

	const handleRefresh = createHandleRefresh();
	const handleCancel = createHandleCancelOrder();

	return (
		<Card>
			<CardContent>
				<Box display="flex" justifyContent="space-between" mb={2}>
					<Box>
						<Box mb={1}>
							<Chip label={label} size="small"/>
						</Box>
						<Typography
							variant="caption"
							color="textSecondary"
							component="div"
						>
							{moment(order.deliveryDate).format("DD.MM.YYYY")}
						</Typography>
					</Box>
					<Box>
						<Actions>
							<IconButton
								size="small"
								disabled={busy || !handleRefresh}
								onClick={handleWithBusyIndicator(handleRefresh)}
							>
								<Tooltip title="Verkauf in Leasman prüfen">
									<Refresh />
								</Tooltip>
							</IconButton>
							<IconButton
								size="small"
								disabled={busy || !hasRole(UserRole.SalesAdmin) || !handleCancel}
								onClick={handleWithBusyIndicator(handleCancel)}
							>
								<Tooltip title="Bestellung stornieren">
									<Block />
								</Tooltip>
							</IconButton>
						</Actions>
					</Box>
				</Box>
				<Box>
					<Typography variant="h5">
						{buyerName}
					</Typography>
					<Typography
						variant="caption"
						color="textSecondary"
					>
						{order.buyer.street} ∙ {order.buyer.zipCode} {order.buyer.city}
					</Typography>
				</Box>
				{order?.deliveryDate && (
					<Box mt={2}>
						<Typography variant="body2">
							Vereinbartes Lieferdatum: <span>{moment(order.deliveryDate).format("L")}</span>
						</Typography>
					</Box>
				)}
				<Box mt={2}>
					<Table noBorder size="small" padding="none">
						<TableHead>
							<TableRow>
								<TableCell>
									<Typography color="textSecondary" variant="caption">
										Text
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography color="textSecondary" variant="caption">
										Betrag
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{order.lines.map((l, i) => (
								<TableRow key={i}>
									<TableCell>{l.text}</TableCell>
									<TableCell align="right">{formatCurrency(l.grossAmount)}</TableCell>
								</TableRow>
							))}
							{order.lines.length > 1 && (
								<TableRow>
									<TableCell
										colSpan={2}
										align="right"
									>
										<Box component="span" fontWeight="fontWeightBold">
											{formatCurrency(_.sum(order.lines.map(l => l.grossAmount)))}
										</Box>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</Box>
			</CardContent>
		</Card>
	);
};

export default SalesOrderCard;
